@import './variables';

.container {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    img {
        width: 50%;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &:hover {
        background-color: rgb(218, 244, 253);
        img {
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
                0 10px 10px rgba(0, 0, 0, 0.22);
        }
    }
}

.songDescription {
    padding-left: 1rem;
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: 0.7rem;
    }
}

.selected {
    background-color: rgb(158, 214, 233);
}
