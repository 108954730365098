@import './variables';

.library {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    width: 20rem;
    height: 100%;
    box-shadow: 2px 2px 50px rgb(200, 200, 200);
    overflow: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    z-index: 1000;
    h2 {
        padding: 2rem;
    }
}

.open {
    transform: translateX(0%);
    opacity: 1;
}

@media screen and (max-width:768px) {
    .library{
        width: 100%;
        z-index: 9;
    }
}