@import './variables';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3 {
    color: $text;
}

h3,
h4 {
    font-weight: 400;
    color: $small-text;
}

button {
    outline: none;
}

//firefox scrollbar
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
//webkit-scrollbar
*::-webkit-scrollbar {
    width: 5px;
    height: 100%;
}
*::-webkit-scrollbar-track {
    background: transparent;
}
*::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
}

.App{
    transition: all .3s ease ;
}

.libraryActive{
    margin-left: 30%;
}
