@import './variables';

.container {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 20%;
        border-radius: 15%;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    h2 {
        padding: 3rem 1rem 1rem 1rem;
    }
    h3 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .container{
        img{
            width: 60%;
        }
    }
}
